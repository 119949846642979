let defaultConfig = 'stage'
if (process.env.NODE_ENV === 'production') {
  defaultConfig = 'prod'
}
if (process.env.NODE_ENV === 'stage') {
  defaultConfig = 'stage'
}

const configName = process.env.CONFIG || defaultConfig
const currentConfig = require(`./${configName}.env`)
console.log(currentConfig)
module.exports = currentConfig
